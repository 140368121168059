<template>
  <div class="home">
    <!-- 头部 -->
    <div class="header-wrapper">
      <HeaderNav
          @globalSearch="globalSearch($event)"
      />
    </div>
    <!-- 内容区域-->
    <div class="home-content-wrapper">
      <router-view/>
    </div>
    <!--    底部-->
    <Foot/>
    <!--    首页背景图-->
    <div class="home-bg-wrapper">
      <img src="@/assets/websiteImages/home/home-bg.png" alt="">
    </div>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import Foot from '@/components/Foot.vue'
import { starrySky } from "@/utils/sys-ui.js"
export default {
  name: "Home",
  components: {
    HeaderNav,
    Foot
  },
  methods: {
    globalSearch(searchValue) {
      this.$store.dispatch('getCourseTypeList', searchValue).then(() => {
        if (this.$route.path !== '/classPage') {
          this.$router.push({
            path: `/classPage`
          })
        }
      })
    }
  }
};
</script>
<style lang="scss">
.home {
  min-height: 100vh;

  .header-wrapper {
    height: 80px;
    position: relative;
    z-index: 10000000;
  }

  .home-content-wrapper {
    width: 1200px;
    min-height: calc(100vh - 430px);
    margin: 20px auto;
  }
  .home-bg-wrapper {
    position:fixed;
    left:0;
    top:0;
    z-index:0;
    img {
      width:100vw;
    }
  }
}
</style>

