<template>
  <div class="header-content-wrapper">
    <div class="header" :class="{headerFixed:$store.state.scrollTop >= 100}">
      <div class="content">
        <div class="header-left-wrapper flex-rowStartColCenter">
          <div class="logo">
            <router-link to="/" class="flex-rowStart"><img src="@/assets/websiteImages/home/websiteLogo.png" alt="">
            </router-link>
          </div>
          <div class="split-wrapper" v-show="$store.state.sonCompanyLogo">/</div>
          <div class="logo sonCompanyLogo" v-show="$store.state.sonCompanyLogo">
            <router-link to="/"><img :src="$store.state.sonCompanyLogo" alt=""></router-link>
          </div>
        </div>
        <div class="header-tab">
          <div class="link-tab" v-for="(tab, index) in headerTabs" :key="index">
            <div class="tabWrapper">
              <img :src="tab.icon" style="margin-right:5px;" alt="">
              <span>{{ tab.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // classifyList: [
      //   {
      //     "id": 7,
      //     "title": "公司简介",
      //     "rightData": {
      //       "companyType": "cjzhCompany",
      //       "tabType": "companyProfile",
      //       "title": "辰极智航（北京）科技有限公司",
      //       "content": "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;辰极智航（北京）科技有限公司成立于2013年，是一家提供未来智能科技的高新技术企业，设立于北京市丰台区美丽的永定河畔，与美丽的宛平城依依相望，并在全国各地设有分公司。</br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业自成立以来，秉持着“技术解读现在，智慧领航未来”的先进理念，始终坚持诚信立业的经营原则，在智能无人系统、未来通信技术、智能防御装备、大数据服务平台、电子元器件及电子学材料、游戏仿真系统等多个领域具有先进理念和关键核心技术，在需求分析、方案论证、系统集成、技术转化等方面具有丰富经验。<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;辰极智航把高精尖技术的研发和应用作为企业战略目标，通过智能化技术改善人们的生活方式，提升我国的高科技智能化国防军事力量，以应对日益复杂的国际局势。",
      //       "cover": require("@/assets/websiteImages/cjzh-images/cjzh-companyProfile.jpg"),
      //     }
      //   },
      //   {
      //     "id": 11,
      //     "title": "企业架构",
      //     "rightData": {
      //       "companyType": "cjzhCompany",
      //       "tabType": "companyStructure",
      //       "cover": require("@/assets/websiteImages/cjzh-images/cjzh-companyStructure.png"),
      //     }
      //   },
      //   {
      //     "id": 13,
      //     "title": "企业文化",
      //     "rightData": {
      //       "companyType": "cjzhCompany",
      //       "tabType": "enterpriseCulture",
      //       "title": "“创、质、和、法”，既是企业的文化，也是对企业和员工的基本要求。",
      //       "list": [
      //         {
      //           text: "创",
      //           content: "以创新精神做企业，以创新思维研技术。"
      //         },
      //         {
      //           text: "质",
      //           content: "产品要有过硬质量，做人要有优良品质。"
      //         },
      //         {
      //           text: "和",
      //           content: "内部关系融洽和气，对外交往合作共赢。"
      //         },
      //         {
      //           text: "法",
      //           content: "遵守国家法律法规和保密规定，遵守公司规章制度和商业机密。"
      //         },
      //
      //       ],
      //       "cover": require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture.png"),
      //       "cover2": require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png")
      //     }
      //   },
      //   {
      //     "id": 15,
      //     "title": "公司资质",
      //     "rightData": {
      //       "companyType": "cjzhCompany",
      //       "tabType": "companyQualification",
      //       list: [
      //         {
      //           text: "中关村高新技术企业认定"
      //         },
      //         {
      //           text: "高新技术企业证书"
      //         },
      //         // {
      //         //   text: "武器装备承研承制认证"
      //         // },
      //         // {
      //         //   text: "军工保密二级资质"
      //         // },
      //         {
      //           text: "北京市“专精特新”中小企业"
      //         },
      //         {
      //           text: "知识产权试点单位证书"
      //         },
      //       ],
      //       cover: require('@/assets/websiteImages/cjzh-images/cjzh-companyQualification.png'),
      //     }
      //   },
      //   {
      //
      //     "id": 9,
      //     "title": "公司团队",
      //     "rightData": {
      //       "companyType": "cjzhCompany",
      //       "tabType": 'companyTeam',
      //       // "list": [
      //       //   {
      //       //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-party-1.png"),
      //       //     name: "章双全",
      //       //     position: "研发部：研发总监",
      //       //     description: "个人简介：毕业于清华大学材料科学与工程专业，多次承担军委后勤保障部、军科委、装备部等重点项目。"
      //       //   },
      //       //   {
      //       //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-party-2.png"),
      //       //     name: "马衍青",
      //       //     position: "市场部：市场副总监",
      //       //     description: "个人简介：毕业于清华大学材料科学与工程专业，多次承担军委后勤保障部、军科委、装备部等重点项目。"
      //       //   },
      //       //   {
      //       //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-party-3.png"),
      //       //     name: "李伟",
      //       //     position: "研发部：研发项目经理",
      //       //     description: "个人简介：毕业于清华大学材料科学与工程专业，多次承担军委后勤保障部、军科委、装备部等重点项目。"
      //       //   },
      //       //   {
      //       //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-party-4.png"),
      //       //     name: "郑铁宁",
      //       //     position: "研发部：结构设计",
      //       //     description: "个人简介：毕业于清华大学材料科学与工程专业，多次承担军委后勤保障部、军科委、装备部等重点项目。"
      //       //   },
      //       //   {
      //       //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-party-5.png"),
      //       //     name: "苏成臣",
      //       //     position: "研发部：工程师",
      //       //     description: "个人简介：毕业于清华大学材料科学与工程专业，多次承担军委后勤保障部、军科委、装备部等重点项目。"
      //       //   }
      //       // ],
      //       "list": [
      //         {
      //           cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
      //           name: "xxx",
      //           position: "创始人",
      //           // description: "毕业院校、专业、承担项目情况。"
      //         },
      //         {
      //           cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
      //           name: "xxx2",
      //           position: "总经理",
      //           // description: "毕业院校、专业、承担项目情况。"
      //         },
      //         {
      //           cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
      //           name: "xxx3",
      //           position: "总经理",
      //           // description: "毕业院校、专业、承担项目情况。"
      //         },
      //         {
      //           cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
      //           name: "xxx4",
      //           position: "研发总监",
      //           // description: "毕业院校、专业、承担项目情况。"
      //         },
      //         {
      //           cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
      //           name: "xxx5",
      //           position: "研发副总监",
      //           // description: "毕业院校、专业、承担项目情况。"
      //         },
      //         {
      //           cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
      //           name: "xxx6",
      //           position: "市场销售中心总监",
      //           // description: "毕业院校、专业、承担项目情况。"
      //         },
      //         {
      //           cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
      //           name: "xxx7",
      //           position: "核心骨干",
      //           // description: "毕业院校、专业、承担项目情况。"
      //         },
      //         {
      //           cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
      //           name: "xxx8",
      //           position: "核心骨干",
      //           // description: "毕业院校、专业、承担项目情况。"
      //         },
      //         {
      //           cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
      //           name: "xxx9",
      //           position: "核心骨干",
      //           // description: "毕业院校、专业、承担项目情况。"
      //         },
      //         {
      //           cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
      //           name: "xxx10",
      //           position: "核心骨干",
      //           // description: "毕业院校、专业、承担项目情况。"
      //         }
      //       ],
      //     }
      //   },
      //   {
      //     "id": 25,
      //     "title": "核心技术",
      //     "rightData": {
      //       "companyType": "cjzhCompany",
      //       "tabType": "coreTechnology",
      //       "list": [
      //         {
      //           icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon1.png"),
      //           title: "卫星终端",
      //           text: "可实现高动态环境下低轨卫星信号的快速捕获和稳定跟踪，宽带频率切换下的相控阵天线高精度指向技术，具备低成本、轻量化、低功耗等特点。"
      //         },
      //         {
      //           icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon2.png"),
      //           title: "数据链",
      //           text: "具有体系统一、自主演进、弹性互联、韧性顽存、一端多模能力特点的低延时、远距离、小型化的数据链路可靠传输技术。"
      //         },
      //         {
      //           icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon3.png"),
      //           title: "自组网",
      //           text: "可实现自组网通信链路的远距离高速率多节点长加密。"
      //         },
      //         {
      //           icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon4.png"),
      //           title: "卫星通信終端基带技术",
      //           text: "具备小型化、低功耗特点。"
      //         },
      //       ],
      //       "cover": require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology.png"),
      //     }
      //   },
      //   {
      //     "id": 26,
      //     "title": "公司产品",
      //     "rightData": {
      //       "companyType": "cjzhCompany",
      //       "tabType": "companyProducts",
      //       "productCharacteristicsTitle": "产品特点",
      //       "productDomainTitle": "应用领域",
      //       "list": [
      //         // {
      //         //   "cover": require("@/assets/websiteImages/cjzh-images/companyProducts1.png"),
      //         //   "title": "抛物面天线 RE-T370Z",
      //         //   "characteristicsText": "可作为宽带通信网和抗强干扰通信网的业务站，并可在中心站网控的授权下，构建宽带和抗强干扰的虚拟专网，支持虚拟专网的配置管理、性能管理、故障管理和综合态势管理等功能，可接收和订阅上级网络管理节点推送的逻辑子网的网络运行态势信息并进行呈现。",
      //         //   "domainText": "主要编配于战区及军以上作战单元。",
      //         // },
      //         {
      //           "cover": require("@/assets/websiteImages/cjzh-images/companyProducts2.png"),
      //           "title": "抛物面天线 RE-Z090B",
      //           "characteristicsText": "支持Ku频段和Ka频段分时工作；在有信标条件下，可独立完成对星；在无信标条件下，可与卫星信道设备配合完成对星;位置及姿态信息采集；能够存储并调用1000颗以上卫星的星位参数，并支持外部输入星位参数；在自动跟踪模式下，能够根据手动输入或自动获取的位置信息及姿态信息、星位参数自动控制天线对准目标卫星。",
      //           "domainText": "用于卫星箱式站与固定站、车载站等站型之间语音、短信息、数据业务双向传输。",
      //         },
      //         {
      //           "cover": require("@/assets/websiteImages/cjzh-images/companyProducts3.png"),
      //           "title": "车载自组网通信设备 RE-A020LY",
      //           "characteristicsText": "单个设备故障不会影响整个系统其它设备的使用不依赖基站、高效、快速组成无中心的无线宽带网络，支持多跳中继、能有效拓展无线网络的覆盖半径/IP/UDP网络协议线，全IP组网，易于互联，通信距离：单跳传输≥6km（通视环境）。",
      //           "domainText": "应急通信、车载指挥、森林防火、石油勘探。  ",
      //         },
      //         // {
      //         //   "cover": require("@/assets/websiteImages/cjzh-images/companyProducts4.png"),
      //         //   "title": "抛物面天线 RE-T240Z",
      //         //   "characteristicsText": "可作为宽带通信网和抗强干扰通信网的业务站，并可在中心站网控的授权下，构建宽带和抗强干扰的虚拟专网，支持虚拟专网的配置管理、性能管理、故障管理和综合态势管理等功能，可接收和订阅上级网络管理节点推送的逻辑子网的网络运行态势信息并进行呈现。支持对天线、发射机、卫星信道设备等主要设备工作状态、参数的监视和控制。支持宽带通信网、抗强干扰通信网之间的话音和 IP 数据业务互联互通。支持宽带通信网、抗强干扰通信网与军用自动电话网之间话音业务互通。",
      //         //   "domainText": "通用型 2.4 米 Ka/Ku 双频段卫星通信车主要编配于战区及军以上作战单元。",
      //         // },
      //         // {
      //         //   "cover": require("@/assets/websiteImages/cjzh-images/companyProducts5.png"),
      //         //   "title": "抛物面天线 RE-T050F",
      //         //   "characteristicsText": "支持抗强电磁脉冲攻击能力；多轨道、有信标、无信标自跟踪能力； 支持 Ka 宽频工作能力；支持宽带、抗强等多种波形应用；支持话音、同/异步数据、IP 数据、短消息和集群等多业务处理； 支持远程接入和组网通信，管理控制、人机交互和升级扩展能力；支持小型化低功耗、快速展开撤收。",
      //         //   "domainText": "主要编配于核反击作战力量、特种作战部队。",
      //         // },
      //         {
      //           "cover": require("@/assets/websiteImages/cjzh-images/companyProducts6.png"),
      //           "title": "RE-A020SY",
      //           "characteristicsText": "支持 7×24 小时持续工作，IP 67 防护等级，防水防盐雾；支持视频、语音、位置、传感器等各类基于 IP 的数据传输;支持自动频谱迁移、跳频工作模式；支持 MIMO 多天线技术，最大通信速率大于 100Mbps。",
      //           "domainText": "应急通信、岸船通信、森林防火、石油勘探。",
      //         },
      //       ]
      //     }
      //   },
      //   {
      //     "id": 24,
      //     "title": "合作伙伴",
      //     "rightData": {
      //       "companyType": "cjzhCompany",
      //       "tabType": "partners",
      //       "cover": require('@/assets/websiteImages/cjzh-images/cjzh-partners.png')
      //     }
      //   },
      //   // {
      //   //   "id": 30,
      //   //   "title": "企业愿景",
      //   //   "rightData": {
      //   //     "companyType": "cjzhCompany",
      //   //     "tabType": "enterpriseVision",
      //   //     "text": "这三年时间为公司稳定发展的成熟期，主要工作是巩固企业成果，全方位立体化的建设公司。在成熟期，公司自主知识产权的科研成果具有国内外领先水平，产品在特定的行业领域和巨大民用市场得到广泛应用，公司建立了优良的品牌和企业诚信，与优良用户建立产期稳定的客户关系，具有完善高效的供应链体系，销售已占有较大的市场规模，同时具有科学合理人才队伍，管理制度具有领先优势，公司已完全具备科创板上市条件。",
      //   //     "cover": require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseVision1.png"),
      //   //   }
      //   // },
      //
      // ],
      headerTabs: [
        {
          icon: require('@/assets/websiteImages/home/icon4.png'),
          text: "关于辰极",
        },
        {
          icon: require('@/assets/websiteImages/home/icon2.png'),
          text: "新闻中心",
        },
        {
          icon: require('@/assets/websiteImages/home/icon1.png'),
          text: "产品中心",
        },
        {
          icon: require('@/assets/websiteImages/home/icon3.png'),
          text: "联系我们",
        },
      ],
    };
  },
  methods: {
    handlerClearSonCompany() {
      this.$store.commit('setBannerList', this.classifyList)
      this.$store.commit('setSonCompanyLogo', undefined)
    },
  },
};
</script>
<style scoped>
.header-content-wrapper {
  background: #fff;
  box-shadow: 0 0 10px #ccc;
}
.header {
  margin:0 auto;
  width: 1200px;
  height: 80px;
  background: #fff;
  overflow: hidden;

}

.headerFixed {
  width:100%;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 9999;
  animation: headerHeight 1s;
}

.content {
  width: 1200px;

  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.split-wrapper {
  line-height: 80px;
  font-size: 40px;
  color: rgb(64, 158, 255);
  margin: 0 26px 0 10px;
}

.sonCompanyLogo {
  position: relative;
  cursor: pointer;
}

.sonCompanyLogo:hover span {
  display: block;
}

.sonCompanyLogo span {
  display: none;
  position: absolute;
  top: 0;
  right: -20px;
  font-size: 20px;

}


.search input {
  width: 100%;
  height: 100%;
  display: block;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  text-indent: .5em;

}

.search img {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 4px;
  top: 10px;
  cursor: pointer;
}

.header-tab {
  font-size: 14px;
  text-align: right;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-tab .link-tab .tabWrapper {
  transition: margin .2s;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
}

.header-tab .link-tab span {
  font-size: 12px;
}

.header-tab .link-tab .tabWrapper:hover {
  margin-top: -10px;
}

.header-tab .link-tab:hover span {
  color: rgb(64, 158, 255);
  border-bottom: 1px solid rgb(64, 158, 255);
}

.header-tab .personalCenter .userAvatar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.header-tab .personalCenter .userAvatar img {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.header-tab .personalCenter .userAvatar span {
  margin-left: 10px;
  font-size: 14px;
}

.header-tab .personalCenter .userAvatar span mark {
  color: rgb(64, 158, 255);
  text-decoration: underline;
}

@keyframes headerHeight {
  from {
    height: 0;
  }
  to {
    height: 80px;
  }
}


</style>
